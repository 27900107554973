export default {
    map: {
        "VL_Masthead_01": "MastheadModule",
        "VL_Chat_01": "ChatModule",
        "CuratedTrayModule": "CuratedTrayModule",
        "VL_RichText_01": 'TextModule',
        "AC UserManagement 04": "UserManagementModule",
        "AC Carousel 04": "CarouselModule",
        "VL_LivePreviewTray_01": "TrayModules/FeaturedTrayModule",
        "VL_Tray_01": "TrayModules/CoreTrayModule",
        "VL_HighlightTray_01": "TrayModules/HighlightTrayModule",
        "VL_LandscapeTray_01": "TrayModules/LandscapeTrayModule",
        "VL_ScheduleTray_01": "TrayModules/ScheduleTrayModule",
        "VL_GroupStreamsTray_01": "TrayModules/GroupStreamsTrayModule",
        "VL_UserManagement_01": "UserManagementModule",
        "VL_Authentication_01": "StandaloneAuthentication",
        "VL_VPPA_01": "StandAloneVPPA",
        "VL_Locate_01": "StandaloneLocateModule",
        "VL_ViewPlans_01": "StandaloneViewplans",
        "VL_ViewPlans_02": "Viewplans02",
        "VL_Subscription_01": "StandaloneSubscribe",
        "AC Thankyou 01": "ThankYou",
        "VL_ThankYou_02": "ThankYou02",
        "VL_ActivateDevice_01": "ActivateDevice",
        "VL_TVE_01": "StandaloneTVE",
        "VL_UpdateAccount_01": "StandaloneUpdateAccount",
        "VL_UpdateAccount_02": "StandaloneUpdateAccount02",
        "VL_TVE_Activate_01": "StandaloneActivateTVE",
        "VL_VideoDetail_01": "VideoDetailModule",
        "VL_CategoryDetailWithTabs": "CategoryDetailV2",
        "VL_GameDetail_01": "GameDetailModule",
        "VL_GameDetail_02": "GameDetailModule02",
        "VL_VideoPlaylistDetail_01": "PlaylistDetailModule",
        "VL_ArticleDetail_01": "ArticleDetailModule",
        "VL_StandaloneSearch_01": "StandaloneSearch",
        "VL_SearchTrayModule_01": "TrayModules/SearchTrayModule",
        "VL_ShowDetail_01": "ShowDetailModule",
        "VL_WatchList_01": "WatchListDetailModule",
        "VL_WatchHistory_01": "WatchHistoryDetailModule",
        "VL_AccountInfo_01": "StandAloneAccountInfo",
        "VL_Billing_01": "StandAloneBilling",
        "VL_DeviceMangement_01": "StandAloneDeviceManage",
        "VL_Personalisation_01": "StandAlonePersonalization",
        "VL_Personalisation_02": "StandAlonePersonalization02",
        "VL_Footer_01": "VLFooterModule",
        "VL_Noification_01": "StandAloneNotificationAlerts",
        "VL_Location_01": "StandAloneLocation",
        "VL_CustomerCare_01": "StandAloneCustomerCare",
        "VL_MultiPageContainer_01": "MultiPageModule",
        "VL_ContinueWatchingTray_01": "TrayModules/ContinueWatchingTray",
        "VL_StandaloneVideoPlayer_01": "StandaloneVideoPlayer",
        "AC RawHtml 01": "RawHTMLModule",
        "VL_CategoryDetail_01": "CategoryDetailModule",
        "AC Grid 01": "GridItemModule",
        "VL_DeleteAccount_01": "UserManagementModule/DeleteAccount",
        'VL_LinearChannelDetailModule_01': 'LinearChannelDetailModule',
        'VL_LinearChannelStandaloneModule_01': 'StandaloneLinearChannel',
        "VL_SimpleImageBanner_01": "ImageBanner",
        "VL_Redeem_01": "RedeemGiftCard",
        "VL_SeasonStandingsLeaderboard_01": "SeasonStanding",
        "VL EmbeddedVideoPlayer 01": "EmbededVideoPlayer",
        "VL_Splash_DownloadApp": "SplashScreens/SplashDownloadApp",
        "VL_Splash_Newsletter": "SplashScreens/SplashNewsletter",
        "VL_Splash_NewsletterAndSignUp": "SplashScreens/SplashNewsletterAndSignUp",
        "VL_Splash_PromotionalOffer": "SplashScreens/SplashPromotionalOffer",
        "VL_Splash_Schedule": "SplashScreens/SplashSchedule",
        "VL_CollectionDetail_01": "CollectionDetailModule",
        "VL_CollectionDetail_02": "CollectionDetailModule02",
        "VL_Leaderboard_01": "Leaderboard",
        "VL_TeamChampionshipLeaderbaord_01": "TeamChampionship",
        "VL_Library_01": "LibraryModule",
        "VL_LanguageSettings_01": "UserManagementModule/LanguageSettings",
        "VL_VERTICAL_VIDEO_TRAY_01": "TrayModules/VerticalVideoTrayModule",
        "VL_Authentication_02": "AuthenticationV2Module",
        "VL_Checkout_01": "CheckoutModule",
        "VL_PlanBanner_01": "PlansBanner",
        "VL_VerticalVideoTray_01": "TrayModules/VerticalVideoTrayModule",
        "VL_RecommendationTray_01": "TrayModules/RecommendationTrayModule"
    },
};
